<template>
  <bg-style :bg="model.background" class="month-card p-5 relative" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }">
    <template v-if="!noData">
      <rich-text v-if="model.titleVisible" v-model="model.title" :editing="editing" :class="$store.getters.isDesktop && 'xl:text-[40px]'" class="text-[20px] w-full relative title mt-8" theme="snow" :disabled="!editing" />
      <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :editing="editing" class="mt-3 relative text-[20px]" theme="snow" :disabled="!editing" />
      <div class="month-card__content mt-10">
        <div class="content-area">
          <bg-style class="left-thumb relative" :bg="model.banner">
            <CoverBlock
              v-if="editing"
              :title="$t('edit.setImage')"
              class="logo_cover_block"
              @click="handleShowSetImg"
            />
          </bg-style>
          <div class="right-detail">
            <div>
              <div class="subtitle w-full items-center flex justify-between mb-2 relative">
                <span>{{ $t('siteBuild.monthCardWidget.nowGet') }}</span>
                <i class="el-icon-warning-outline text-18 cursor-pointer" @click="handleShowRule"/>
              </div>
              <div class="list">
                <div v-for="(item, index) in nowList" :key="index" class="item">
                  <img class="w-full h-full" :src="item.gift_goods_logo" alt="">
                  <div class="number">{{ item.num }}</div>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="subtitle w-full flex items-center justify-between mb-2 relative">
                <span>{{ $t('siteBuild.monthCardWidget.dayGet') }}</span>
<!--                <i class="el-icon-warning-outline text-18 cursor-pointer" @click="handleShowRule" />-->
              </div>
              <div class="list">
                <div v-for="(item, index) in dayList" :key="index" class="item">
                  <img class="w-full h-full" :src="item.gift_goods_logo" alt="">
                  <div class="number">{{ item.num }}</div>
                </div>
              </div>
            </div>
            <div v-if="showCardCountTime" class="time relative text-center mt-5 flex items-center justify-center">
              <i class="el-icon-timer text-20"></i>
              <span class="text-20">{{ cardCountTime }}</span>
            </div>
            <div class="flex justify-center gap-20 px-10 mt-10" :class="$store.getters.isMobile && ['flex-col', '!gap-2', '!mt-5', '!px-0']">
              <bg-style class="month-card__buy" :class="showCountTime && 'w-auto'" :bg="model.buyBackground" @click.native="handlePay">
                <div class="flex items-center">
                  <i v-if="loading" class="el-icon-loading mr-2" :style="getTextColor(model.buyText)"/>
                  <span v-if="showCountTime" class="relative">{{ countTime }}</span>
                  <span v-else class="relative" :style="getTextColor(model.buyText)">{{ isCardUser ? $t('siteBuild.consecutive') : priceInfo.priceTxt }}</span>
                </div>
                <div v-if="detail.original_value > 0 && !isCardUser" class="relative line-through">{{ originPriceInfo.priceTxt }}</div>
              </bg-style>
              <template v-if="!showCountTime">
                <bg-style v-if="(isCardUser && canGet) || editing" class="month-card__button" :bg="model.getBackground" @click.native="handleGet">
                  <i v-if="getLoading" class="el-icon-loading mr-2" :style="getTextColor(model.buyText)"/>
                  <span class="relative" :style="getTextColor(model.getText)">{{ canGetNum }}{{ $t('siteBuild.activity.get') }}</span>
                </bg-style>
                <bg-style v-if="(isCardUser && !canGet) || editing" class="month-card__button" :bg="model.receiveBackground">
                  <i v-if="getLoading" class="el-icon-loading mr-2" :style="getTextColor(model.buyText)"/>
                  <span class="relative" :style="getTextColor(model.receiveText)">{{ $t('siteBuild.activity.haveGet') }}</span>
                </bg-style>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <el-empty v-else class="relative" description=" " />
    <DeskPayModal
      v-if="deskPay.visible"
      :desk-pay="deskPay"
      :store-lang="storeLang"
      :card-data="detail"
      :lang-id-data="$store.state.locale.langIdData"
      @close="closePay"
    />
    <rule-dialog ref="rule" :model="model" />
  </bg-style>
</template>

<script>
import { Empty, Message } from "element-ui"
import moment from "moment"
import {mapState} from "vuex";
import RuleDialog from './rule.vue'
import Bus from "~/site/model/bus"
import RichText from "~/components/richText/index.vue"
import {DeviceEnum} from "~/enums/deviceEnum"
import tools, {checkUser, formatZoneTime, getTextColor, generatePayUrl} from "~/utils"
import CoverBlock from "~/components/common/CoverBlock.vue"
import DeskPayModal from "~/components/pay/deskPayModal.vue"
import {default_site_lang, isBuildWebSite} from "~/config"
import {isMobile} from "~/utils/types"

export default {
  name: 'MonthCardWidget',
  components: {
    DeskPayModal,
    CoverBlock,
    RichText,
    RuleDialog,
    [Empty.name]: Empty
  },
  props: {
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      canGet: false,
      countTime: '00:00:00',
      cardCountTime: '00:00:00',
      detail: {},
      nowList: [],
      dayList: [],
      startTime: '',
      timer: '',
      isCardUser: false,
      loading: false,
      noData: false,
      storeLang: default_site_lang,
      return_url: '',
      getLoading: false,
      cardStopTime: '',
      canGetNum: 0,
      priceInfo: {},
      originPriceInfo: {}
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    showCountTime() {
      return this.countTime !== '00:00:00'
    },
    showCardCountTime() {
      return this.cardCountTime !== '00:00:00'
    },
    id() {
      return this.model.cardId
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
    })
  },
  watch: {
    id(n, o) {
      if (n !== o) {
        this.init()
      }
    }
  },
  created() {
    this.storeLang = tools.getStoreLang(this)
  },
  mounted() {
    this.init()
    this.return_url = `${window.location.protocol}//${window.location.hostname === 'localhost' ? 'http://localhost:3002/preview/1003574/cn/' : window.location.host}${window.location.pathname}`
    Bus.$on('reloadActivity', () => {
      this.init()
    })
  },
  methods: {
    handleShowRule() {
      if (this.editing) return
      this.$refs.rule.init()
    },
    getTextColor,
    async init() {
      this.clearData()
      await this.$store.dispatch('goods/getCardList', this)
      this.getDetail()
    },
    clearData() {
      this.canGet = false
      this.canGetNum = 0
      this.isCardUser = false
    },
    async handleGet() {
      const params = {
        project_id: this.userInfo.project_id,
        item_id: this.detail.id
      }
      this.getLoading = true
      const [err, res] = await this.$utils.to(this.$api.good.getCardGift(params))
      this.getLoading = false
      if (!err) {
        Message.success(res.data || 'Success')
        await this.init()
      }
    },
    async handlePay() {
      if (this.loading || this.editing) return
      const isSandBox = !isBuildWebSite
      await checkUser(this)
      const StoreIpInfo = tools.getLangByip(this)
      const { currency } = StoreIpInfo
      const { project_id, merchant_id } = this.userInfo
      const ipInfo = tools.getxfip(localStorage)
      const priceInfo = tools.getPriceInfo(this.detail.prices_setting, StoreIpInfo, this)
      const price = +priceInfo.price;
      const device = isMobile() ? 'mobile' : 'desktop'
      const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
      const params = {
        project_id,
        merchant_id,
        currency,
        amount: price,
        from_preview_mode: isSandBox,
        items: [
          {
            item_id: this.detail.id,
            num: 1,
            amount: price,
            price,
            currency,
            virtual_currency: currency,
            virtual_currency_amount: price,
          },
        ],
        settings: {
          device,
          language: lang,
          show_order: false,
          return_url: this.return_url,
        },
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.order.createOrder(params, ipInfo))
      this.loading = false
      if (!err) {
        generatePayUrl(res, (payUrl) => {
          this.deskPay = {
            visible: true,
            isGoPay: true,
            payUrl,
            orderCode: res.code
          }
        })
      }
    },
    closePay() {
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
      this.init()
    },
    getPrice() {
      const StoreIpInfo = tools.getLangByip(this)
      this.priceInfo = tools.getPriceInfo(this.detail.prices_setting, StoreIpInfo, this)
      this.originPriceInfo = tools.getPriceInfo(this.detail.prices_setting, StoreIpInfo, this, 'originalPrice')
    },
    getDetail() {
      const current = this.$store.state.goods.cardList.find(item => item.id === this.model.cardId)
      if (!current) {
        this.noData = true
        return
      }
      this.noData = false
      this.nowList = current.gift_packs_list
      this.dayList = current.gift_list
      this.startTime = current.start_time
      this.cardStopTime = current.card_stop_time
      this.isCardUser = current.is_card_flag
      this.canGet = current.can_card_gift_flag
      this.canGetNum = current.can_card_gift_num
      this.detail = current
      this.getPrice()
      this.countdown()
      this.cartCountdown()
    },
    cartCountdown() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.cardStopTime * 1000).value
      const leftTime = end - now;
      let d = 0; let h = 0; let m = 0; let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.cardCountTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) setTimeout(this.cartCountdown, 1000)
    },
    countdown() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.startTime * 1000).value
      const leftTime = end - now;
      let d = 0; let h = 0; let m = 0; let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.countTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) setTimeout(this.countdown, 1000)
    },
    handleShowSetImg() {
      this.SiteMenu.showImageWithKey('month-card-banner')
    }
  }
}
</script>

<style lang="less">
.month-card {
  &.is-mobile {
    .month-card__button {
      width: 100%;
      padding: 0 5px;
    }
    .month-card__content {
      .content-area {
        width: 100%;
      }
      .left-thumb {
        width: 50%;
        flex-shrink: 0;
      }
      .right-detail {
        width: 50%;
        .list {
          .item {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  &__button {
    width: 80px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--button-border-radius-px);
    overflow: hidden;
    cursor: pointer;
  }
  &__buy {
    height: auto;
    width: auto;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--button-border-radius-px);
    overflow: hidden;
    cursor: pointer;
    flex-direction: column;
  }
  &__content {
    .content-area {
      width: 800px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .left-thumb {
        width: 240px;
        flex-shrink: 0;
      }
      .right-detail {
        width: 70%;
        padding-left: 20px;
        .list {
          display: flex;
          align-items: center;
          width: 100%;
          overflow: auto;
          .item {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            margin-right: 20px;
            position: relative;
            .number {
              position: absolute;
              bottom: 3px;
              right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
