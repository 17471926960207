<template>
  <widget-content class="widget-footer" :class="isMobile && 'is-mobile'" :bg="model">
    <div
      :class="isMobile ? 'footer_content_mobile relative z-10' : 'footer_content_desk relative z-10'"
      :style="{
        'color': model.footerFontColorShow
          ? model.footerFontColor
          : 'unset',
      }"
    >
      <!-- logo & text -->
      <div class="footer_logo_text">
        <!-- logo -->
        <div v-if="model.logoImageShow" class="footer_logo flex-center">
          <img
            v-if="model.logoImage || DefaultImage.Footer"
            :src="model.logoImage || DefaultImage.Footer"
            class="h-full rounded-sm"
            alt
          />
          <CoverBlock
            v-if="editing"
            :title="$t('edit.setImage')"
            class="logo_cover_block"
            @click="handleShowLogo()"
          />
        </div>
        <!-- text模块 -->
        <template v-if="!isHaveImage">
          <div v-for="block in textImageData" :key="block.id" :model="block" class="footer_text">
            <rich-text
              v-model="block.text"
              :editing="editing"
              :disabled="!editing"
              theme="snow"
              placement="top"
            />
          </div>
        </template>
        <!-- text image模块 兼容有图片的数据 -->
        <div v-if="isHaveImage" class="footer_text_image">
          <div
            v-for="block in textImageData"
            :key="block.id"
            :model="block"
            class="item_text_image"
          >
            <FooterImage
              v-if="block.name === 'FooterImageWidgetModel'"
              :key="block.id"
              class="footer-image"
              :editing="editing"
              :max-height="60"
              :block="{
                 ...block,
                 logoImageShow: true
                }"
            />
            <rich-text
              v-if="block.name === 'FooterTextWidgetModel'"
              v-model="block.text"
              :editing="editing"
              :disabled="!editing"
              theme="snow"
              placement="top"
            />
          </div>
        </div>
      </div>
      <!-- lang select -->
      <div v-if="showLangSelect" class="footer_lang_select flex-center">
        <LangSelectComponent source="footer" :editing="editing" :device="device"></LangSelectComponent>
      </div>
      <!-- 分割线 -->
      <div
        v-if="(model.logoImageShow || textImageData.length > 0) && (linkData.length > 0 || model.showCopyRight)"
        :class="['footer_split_line', model.showSplitLine ? 'split_line_block' : 'split_line_none']"
        :style="model.showSplitLine ? {backgroundColor: model.splitLineColor} : {}"
      />
      <!-- quick link模块 -->
      <template v-if="!isMobile">
        <template v-if="linkData.length > 0">
          <div
            v-for="block, index in linkData"
            :key="block.id"
            :model="block"
            :class="['footer_link flex', model.showCopyRight ? 'justify-between' : 'justify-center']"
          >
            <FooterQuickLink
              :key="block.id"
              :model="block"
              :editing="editing"
              :is-mobile="isMobile"
              :class="{'flex-1' : model.showCopyRight}"
            />
            <div v-if="model.showCopyRight" class="flex items-end justify-end ml-[120px]">
              <rich-text
                v-if="index === linkData.length -1"
                v-model="model.copyRight"
                :editing="editing"
                :disabled="!editing"
                theme="snow"
                :no-tool-bar="true"
                :class="['text-[14px] m-0 p-0', {'min-w-[100px]': !model.copyRight}]"
              />
            </div>
          </div>
        </template>
        <div
          v-if="linkData.length === 0 && model.showCopyRight"
          class="flex items-center justify-center"
        >
          <rich-text
            v-model="model.copyRight"
            :editing="editing"
            :disabled="!editing"
            theme="snow"
            :no-tool-bar="true"
            :class="['text-[14px] m-0 p-0', {'min-w-[100px]': !model.copyRight}]"
          />
        </div>
      </template>
      <template v-if="isMobile">
        <div
          v-for="block in linkData"
          :key="block.id"
          :model="block"
          class="flex flex-col items-center justify-center footer_link"
        >
          <FooterQuickLink :key="block.id" :model="block" :editing="editing" :is-mobile="isMobile" />
        </div>
        <div v-if="model.showCopyRight" class="flex items-center justify-center w-full mt-6">
          <rich-text
            v-model="model.copyRight"
            :editing="editing"
            :disabled="!editing"
            theme="snow"
            :no-tool-bar="true"
            :class="['text-[12px] m-0 p-0', {'min-w-[100px]': !model.copyRight}]"
          />
        </div>
      </template>
    </div>
    <!-- <div :class="['footer-content', editing ? 'content_is_edit' :'justify-between']"> -->
    <!-- <div class="footer-item">
        <FooterImage
          class="footer-image"
          :editing="editing"
          :is-logo="true"
          :block="{
            ...model,
            image: model.logoImage || DefaultImage.Footer,
            logoImageShow: model.logoImageShow,
            logoImageOpacity: model.logoImageOpacity
          }"
        />
    </div>-->
    <!-- <div v-for="(block) in model.children" :key="block.id" class="footer-item"> -->
    <!-- <FooterQuickLink
          v-if="block.name === 'FooterQuickLinkWidgetModel'"
          :key="block.id"
          class="footer-quick-link"
          :model="block"
          :editing="editing"
        ></FooterQuickLink>
        <div
          v-if="block.name === 'FooterTextWidgetModel'"
          :key="block.id"
          class="footer-text"
          :model="block"
        >
          <rich-text
            v-model="block.text"
            :editing="editing"
            :disabled="!editing"
            theme="snow"
            placement="top"
          />
    </div>-->
    <!-- 兼容 取消页脚多图显示之前的数据 -->
    <!-- <FooterImage
          v-if="block.name === 'FooterImageWidgetModel'"
          :key="block.id"
          class="footer-image"
          :editing="editing"
          :block="{
            ...block,
            logoImageShow: true
          }"
    ></FooterImage>-->
    <!-- </div>
    </div>-->
    <!-- <div
      v-if="site && site.langSetting && site.langSetting.langPosition === LangPositionEnum.BOTTOM"
      class="lang-select-wrapper"
    >
      <LangSelectComponent :editing="editing"></LangSelectComponent>
    </div>-->
  </widget-content>
</template>

<script>
import { mapState } from 'vuex'
import { FooterWidgetModel } from '../../model/index'
import LangSelectComponent from '../../components/langSelect'
import FooterQuickLink from './quickLink'
import FooterImage from './image.vue'
import WidgetContent from '~/site/components/widgetContent'
import RichText from "~/components/richText";
import { DefaultImage } from '~/enums/defaultImageEnum'

export default {
  name: "FooterWidget",
  components: {
    LangSelectComponent,
    FooterQuickLink,
    WidgetContent,
    FooterImage,
    RichText,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return new FooterWidgetModel()
      }
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      DefaultImage
    }
  },
  computed: {
    ...mapState({
      realDevice: (state) => state.device.device
    }),
    // 是否移动端
    isMobile() {
      const device = this.editing ? this.device : this.realDevice
      return device === 'mobile'
    },
    // 是否含有图片
    isHaveImage() {
      return this.model.children.some(item => item.name === 'FooterImageWidgetModel')
    },
    // 文本集合
    textImageData() {
      return this.model.children.filter(item => item.name === 'FooterTextWidgetModel' || item.name === 'FooterImageWidgetModel') || []
    },
    // 链接集合
    linkData() {
      return this.model.children.filter(item => item.name === 'FooterQuickLinkWidgetModel') || []
    },
    showLangSelect() {
      return this.site && this.site.langSetting && this.site.langSetting.langPosition === 'bottom'
    }
  },
  methods: {
    footerTextChange(text, block) {
      this.$set(block, 'text', text)
      this.$set(block, 'isTextEdit', true)
    },
    handleShowLogo() {
      this.SiteMenu.closeShowImage('logo-image')
    }
  }
}
</script>

<style lang="less" scoped>
.widget-footer {
  color: var(--text-color);
  &.is-mobile {
    margin-top: -28px;
  }
}
.footer_content_desk {
  padding: 36px 0px;
  .footer_logo {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
    .logo_cover_block {
      position: absolute !important;
      height: 40px !important;
    }
  }

  .footer_text {
    width: 660px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .footer_text_image {
    display: flex;
    flex-wrap: wrap;
    .item_text_image {
      width: 20%;
      display: flex;
      justify-items: center;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .footer_lang_select {
    margin-top: 24px;
  }

  .split_line_block {
    margin: 36px 0;
  }

  .split_line_none {
    margin: 18px 0;
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.footer_content_mobile {
  padding: 24px 16px;
  margin-top: 28px;
  .footer_logo {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    .logo_cover_block {
      position: absolute !important;
      height: 40px !important;
    }
  }

  .footer_text {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .footer_text_image {
    .item_text_image {
      width: 100%;

      .footer-image {
        width: 100%;
        height: 60px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .footer_lang_select {
    margin-top: 16px;
  }

  .split_line_block {
    margin: 24px 0;
  }

  .split_line_none {
    margin: 12px 0;
  }

  .footer_link {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.footer_logo_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_split_line {
  width: 100%;
  height: 1px;
  position: relative;
  z-index: 99;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
